<template>
	<div id="addArticle">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item to="/official">微官网管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">添加资讯</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						分类名称
					</div>
					<el-select v-model="article_class_id" placeholder="请选择">
						<el-option v-for="item in ArticleClassList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="u-f-item input-box">
					<div class="input-lebal">
						<span>*</span>
						资讯标题
					</div>
					<el-input v-model="title" placeholder="请输入资讯标题" style="width: 85%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">
						<span>*</span>
						资讯简介
					</div>
					<el-input v-model="problem" type="textarea" placeholder="请输入资讯简介" rows="5" style="width: 85%;"></el-input>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">
						<span>*</span>
						封面图
					</div>
					<div>
						<el-upload
							action="http://hlgzx.zhiyunapi.com/v1/school_api/common/upload"
							list-type="picture-card"
							:on-preview="handlePictureCardPreview"
							:on-remove="handleRemove"
							:limit="1" :multiple="false" :on-success="onSuccess"
							:on-exceed="onExceed" :file-list="fileList"
						>
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;">
						<span>*</span>
						文章内容
					</div>
					<div id="div1" style="width: 85%;"></div>
				</div>
				<div class="u-f input-box">
					<div class="input-lebal" style="margin-top: 5px;"><span></span></div>
					<div class="u-f-item">
						<el-button @click="back">返回</el-button>
						<el-button type="primary" @click="submit">保存</el-button>
					</div>
				</div>
			</div>
			
			<el-dialog :visible.sync="dialogVisible">
			  <img width="100%" :src="dialogImageUrl" alt="">
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import E from 'wangeditor';
export default {
	components: {
		index
	},
	data() {
		return {
			ArticleClassList: [],
			article_class_id: '',
			title: '',
			problem: '',
			pic: '',
			cnt: '',
			dialogImageUrl: '',
			dialogVisible: false,
			id:'',
			fileList:[]
		};
	},
	mounted() {
		let _this = this;
		_this.id = _this.$route.query.id;
		const editor = new E('#div1');
		editor.config.zIndex = 1;
		// 配置 server 接口地址
		editor.config.uploadVideoServer = '/api/upload-video';
		editor.config.uploadImgServer = 'http://hlgzx.zhiyunapi.com/v1/school_api/pic/upload_arr';
		editor.config.uploadFileName = 'file';
		editor.config.height = 500
		editor.config.onchange = function (newHtml) {
			_this.cnt = newHtml;
		};
		editor.create();
		_this.getArticleClassList();
		if(_this.id){
			_this.$api.setting.ArticleDetails({id:_this.id}).then(res=>{
				if(res.data.code==1){
					let data = res.data.data;
					_this.article_class_id = data.article_class_id;
					_this.title = data.title;
					_this.pic = data.pic;
					_this.fileList.push({
						name:data.pic,
						url:'http://hlgzx.zhiyunapi.com'+data.pic
					})
					_this.problem = data.problem;
					_this.cnt = data.cnt;
					editor.txt.html(data.cnt)
				}
			})
		}
	},
	methods: {
		getArticleClassList() {
			this.$api.setting.getArticleClassList({}).then(res => {
				if (res.data.code == 1) {
					this.ArticleClassList = res.data.data;
				}
			});
		},
		handleRemove(file, fileList) {	
			this.pic = '';
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		onSuccess(response,file,fileList){
			if(response.code==1){
				this.pic = response.data.url;
			}else{
				this.$message.error(response.msg)
			}
		},
		onExceed(files, fileList){
			
		},
		submit(){
			if(this.article_class_id==''||!this.article_class_id){return this.$message.error("请选择资讯分类")}
			if(this.title==''||!this.title){return this.$message.error("请输入标题")}
			if(this.pic==''||!this.pic){return this.$message.error("请上传封面")}
			if(this.problem==''||!this.problem){return this.$message.error("请输入简介")}
			if(this.cnt==''||!this.cnt){return this.$message.error("请输入内容")}
			let data = {
				article_class_id:this.article_class_id,
				title:this.title,
				pic:this.pic,
				cnt:this.cnt,
				problem:this.problem
			}
			this.$api.setting.addArticle(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功');
					this.$router.push('/official')
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		back(){
			this.$router.push('/official')
		}
	}
};
</script>

<style lang="scss">
#addArticle {
	.content {
		background-color: #ffffff;
		height: 83vh;
		margin-top: 15px;
		padding: 15px 0;
		overflow-y: auto;
		.input-box {
			padding-left: 15px;
			margin-bottom: 15px;
			.input-lebal {
				width: 150px;
				text-align: right;
				color: #555;
				margin-right: 10px;
				span {
					color: #ff7a65;
					margin-right: 3px;
				}
			}
			.el-upload-list__item-thumbnail{
				object-fit: cover;
			}
		}
	}
}
</style>
